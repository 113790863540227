.rowCenter{
    justify-content: center;
}

.imgPreview{
    margin: 0 15px 0 0;
    padding: 0;
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 500px;
    height: 300px;
    max-height: 30vh;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    background: #f5f5f5;
    background-image: url("/img/noImg.jpg");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    INPUT.imgPreviewInput{
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0;
        cursor: pointer;
    }
    &.is-invalid{
        border-color: #fd397a;
    }
}

.card{
    transition: 0.3s;
    &.active:hover{
        background-color: rgb(241, 255, 241);
    }
    &.deactive{
        background-color: rgb(255, 221, 221);
        &:hover{ background-color: rgb(255, 238, 238); }
    }
}
